<script setup lang="ts">
import type { PlayersActivity, Game } from "@/types";

const props = defineProps<{
	game: Game;
	image: string;
	imagePattern: string;
	hideFavorite?: boolean;
	isGuest?: boolean;
	winnerData?: PlayersActivity;
	isGameOfWeek?: boolean;
	skeleton?: boolean;
	skipGamePreview?: boolean;
}>();

const { t } = useT();
const { open, closeAll } = useTaoModals();
const { isSweepStakes } = useSwitchMode();
const loginGuard = useLoginGuard();

const emit = defineEmits<{ (event: "toggleFavorite"): void; (event: "play"): void }>();

const exclusiveLock = computed(() => props.game.isExclusive && !isSweepStakes.value);

const handleToggle = () => {
	emit("toggleFavorite");
};

const handleClick = () => {
	if (exclusiveLock.value) {
		closeAll();

		loginGuard({
			success: () => {
				open("LazyOModalGamePreviewExclusive", {
					gameId: props.game.id,
					winnerData: props.winnerData
				});
			},
			fail: () => {
				open("LazyOModalSignup");
			}
		});

		return;
	}

	if (!props.game.isOnlyForApp) {
		if (!props.skipGamePreview && !props.isGuest) {
			open("LazyOModalGamePreview", { game: props.game });
			return;
		}

		emit("play");
	}
};
const alt = computed(() => removeHtmlTags(props.game.title || ""));
</script>

<template>
	<div class="game-wrapper" :class="{ 'has-skeleton': skeleton }">
		<AAnimationSkeleton v-if="skeleton" class="skeleton-wrapper">
			<ASkeleton width="100%" height="100%" />
		</AAnimationSkeleton>
		<ACard
			v-else
			:imgAttrs="{
				width: isGameOfWeek ? 334 : 163,
				height: isGameOfWeek ? 286 : 286,
				src: image,
				format: 'webp',
				loading: 'lazy',
				alt
			}"
			variant="game"
			width="auto"
			height="100%"
			:stretch="isGameOfWeek ? '85.6%' : '175.5%'"
			:data-tid="`gcard-${game.id}`"
			@click="handleClick"
		>
			<MGameActivityFeedBadge :winner="winnerData" />

			<div v-if="(game.isNew && !game.isOnlyForApp) || game.isExclusive" class="badge-wrap">
				<ABadge v-if="game.isExclusive" autosize background="var(--gradient-1)">
					<MPrizeFund icon="12/secret-coins" variant="entries" :iconSize="12" :offset="0.5" isSvg>
						<AText type="badge-sm" :modifiers="['uppercase']">{{ t("previewExclusive.badge.exclusive") }}</AText>
					</MPrizeFund>
				</ABadge>

				<ABadge autosize background="var(--gradient-5)">
					<AText type="badge-sm">{{ t("New") }}</AText>
				</ABadge>
			</div>

			<div v-if="game.isOnlyForApp || exclusiveLock" class="lock-overlay">
				<AOverlay :modifiers="['flex-center']" bgColor="var(--a-overlay-100)">
					<NuxtIcon name="40/locked" filled />
				</AOverlay>
			</div>
			<AAnimationFadeIn v-else :duration="0.3">
				<AOverlay bgColor="var(--a-overlay-200)">
					<div class="game-content">
						<div v-if="!isGuest && !hideFavorite" class="top-block">
							<AButton
								class="favorite"
								variant="toolbar"
								:modifiers="game.isFavorite ? ['active'] : undefined"
								:data-tid="`fav-${game.id}`"
								@click.stop="handleToggle"
							>
								<NuxtIcon class="fav-icon" name="20/favorite" filled />
							</AButton>
						</div>
						<div class="center-block">
							<AButton variant="ghost" :modifiers="['circle']" :data-tid="`gplay-${game.id}`">
								<NuxtIcon name="64/play" filled />
							</AButton>
						</div>
					</div>
				</AOverlay>
			</AAnimationFadeIn>
		</ACard>
	</div>
</template>

<style lang="scss">
.icon-favorite-add {
	background-image: url(/assets/icons/20/fav-active.svg);
}

.icon-favorite-remove {
	background-image: url(/assets/icons/20/favorite-notify.svg);
}
</style>

<style scoped lang="scss">
.has-skeleton {
	position: relative;
	--a-skeleton-background-color: var(--cuba);
	padding-top: 70%;
}
.skeleton-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.game-wrapper {
	display: inline-flex;
	flex-direction: column;
	vertical-align: top;

	@media (hover: hover) {
		&:hover .card > div {
			opacity: 1;
		}
	}

	:deep(.card) {
		.game:before {
			content: "";
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: v-bind(imagePattern);
			position: absolute;
			z-index: -1;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: 50% 50%;
		}
		&:hover > div {
			opacity: 1;
		}

		img {
			transform: scale(1);
			transition: all 0.3s ease-in;
		}

		&:hover > img {
			transform: scale(1.1);
		}
	}
}
.lock-overlay {
	font-size: 40px;
	z-index: 3;
}
.game-content {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	.top-block {
		position: absolute;
		display: flex;
		justify-content: flex-end;
		top: 0;
		left: 0;
		right: 0;
		padding: 8px;
	}
	.center-block {
		position: absolute;
		display: flex;
		transform: translateY(-50%);
		top: 50%;
		left: 0;
		right: 0;

		button.ghost {
			transition: all 0.3s ease-in;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			font-size: 64px;
			padding: 4px 8px;
			box-shadow: none;

			&:deep(.nuxt-icon) {
				transition: all 0.3s ease-in;
			}
		}
	}
}

.badge-wrap {
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	position: absolute;
	top: 8px;
	left: 8px;
	z-index: 2;

	span {
		color: var(--neutral);
	}
}

.game-wrapper div.badge-wrap {
	z-index: 3;
}
</style>
